<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        After a reaction went to completion, the crude reaction mixture consisted of two compounds,
        A and B, which were isolated by extraction. After each compound was dried, their masses were
        found to be,
        <number-value :value="mixtureA" unit="\text{mg}" />
        of compound A and
        <number-value :value="mixtureB" unit="\text{mg}" />
        of compound B. Both compounds were individually recrystallized and weighed again. After
        recrystallization, the mass of compound A was
        <chemical-latex content="83 mg" />
        and the mass of compound B was
        <chemical-latex content="75 mg\text{.}" />
        Calculate the percent recovery from recrystallization for both compounds.
      </p>

      <calculation-input
        v-model="inputs.studentAnswerA"
        prepend-text="$\text{Percent recovery for compound A: }$"
        append-text="$\%$"
        :disabled="!allowEditing"
      />
      <calculation-input
        v-model="inputs.studentAnswerB"
        prepend-text="$\text{Percent recovery for compound B: }$"
        append-text="$\%$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUCI51LB_A8Q7',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswerA: null,
        studentAnswerB: null,
      },
    };
  },
  computed: {
    mixtureA() {
      return this.taskState.getValueBySymbol('mixtureA').content;
    },
    mixtureB() {
      return this.taskState.getValueBySymbol('mixtureB').content;
    },
  },
};
</script>
